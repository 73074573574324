<template>
    <div class="card-row-wrap">
        <v-row align="center" class="row--xl ma-xl-n50px card-row">
            <v-col data-aos="fade-up" cols="12" md="6" class="pa-xl-50px">
                <v-card rounded="sm" max-width="670" class="w-100">
                    <v-img :src="image" :aspect-ratio="670 / 446" class="w-100" />
                </v-card>
            </v-col>
            <v-col data-aos="fade-up" data-aos-delay="100" cols="12" md="6" class="pa-xl-50px">
                <tit class="line-height-17 font-secondary">{{ title }}</tit>
                <txt v-if="txt" :class="{ 'mt-4px mt-md-8px': title }" class="txt--xl txt--dark line-height-17">
                    <span v-html="txt" />
                </txt>
                <txt v-if="caption" class="txt--light line-height-17 mt-4px mt-md-8px">{{ caption }}</txt>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {
        image: { type: String, default: "" },
        title: { type: String, default: "" },
        txt: { type: String, default: "" },
        caption: { type: String, default: "" },
    },
    components: {
        Tit,
        Txt,
    },
};
</script>

<style lang="scss" scoped>
.card-rows > .card-row-wrap:not(:last-child) {
    margin-bottom: 20px;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .card-rows > .card-row-wrap:nth-child(2n) .card-row {
        flex-direction: row-reverse;
    }
    .card-rows > .card-row-wrap:not(:last-child) {
        margin-bottom: 30px;
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>